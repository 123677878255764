import styled from 'styled-components';

// Informacoes
export const Informacoes = styled.section`

  h1 {
    font-weight: 800;
    font-size: 34px;
    color: #2e2e2e;
    position: relative;
    margin-top: 180px;

    @media screen and (max-width: 480px) { 
      line-height: 120%;
      font-size: 30px;
    }

    :before {
      content: '';
      width: 50%;
      height: 5px;
      margin: 0;
      background-color: #780600;
      position: absolute;
      z-index: 99;
      top: 50%;
      right: 0%;
      /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 55% 40%;
    grid-gap: 5%;

    @media screen and (max-width: 480px) { 
      display: inherit;
    }
  }
`;

// Textos
export const Textos = styled.div`
  margin-bottom: 100px;
  
  h2 {
    font-weight: 600;
    font-size: 24px;
  }

  .box {
    background: #F5F5F5;
    padding: 10% 7.5%;
    margin-top: 40px;
    border-radius: 10px;

    h3 {
      font-weight: 600;
      font-size: 24px;
      position: relative;
      margin-bottom: 40px;

      :before {
        content: '';
        width: 25%;
        height: 5px;
        margin: 0;
        background-color: #780600;
        position: absolute;
        z-index: 99;
        bottom: -10px;
        left: 0%;
        /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
      }
    }

    h4 {
      font-weight: bold;
      margin-top: 25px;
    }

    ul {
      padding: 0 0 0 35px;
      margin: 0;

      li {
        font-size: 14px;
        list-style: none;
        margin-bottom: 7.5px;
        position: relative;

        :before {
          content: '';
          width: 20px;
          height: 5px;
          margin: 0;
          background-color: #780600;
          position: absolute;
          z-index: 99;
          /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
          top: 8.5px;
          left: -35px;
        }
      }
    }

    img {
      width: 100%;
    }
  }
`;

// Fotos
export const Fotos = styled.div`
  padding-top: 40px;

  @media screen and (max-width: 480px) { 
    display: none;
  }

  img {
    width: 100%;
    margin-bottom: 60px;
  }
`;