import styled from 'styled-components';
export const Container = styled.section`
  padding-top: 20px;
  position: relative;

  @media screen and (max-width: 480px) { 
    margin-top: 110px;
    padding-top: 0;
  }

  img {
    width: 100%;
    height: 400px;
    z-index: -9;
    position: relative;
    object-fit: cover;
  }
`;

export const Sub = styled.section`
  .Sub {
    display: flex;
    margin-top: 50px;
    margin-bottom: 100px;

    @media screen and (max-width: 480px) { 
      display: inherit;
      margin-bottom: 80px;
    }

    h1 {
      font-weight: 700;
      font-size: 30px;
      color: #2e2e2e;
      position: relative;
      margin-top: 40px;
      :before {
        content: '';
        width: 800px;
        height: 5px;
        margin-top: 90px;
        margin-left: 0px;
        background-color: #780600;
        position: absolute;
        z-index: 99;

        @media screen and (max-width: 480px) { 
          display: none;
        }
      }
    }

    .Box {
      margin-left: 100px;
      margin-top: -180px;
      background-color: #F5F5F5;
      display: flex;
      padding: 40px 70px;
      border-radius: 10px;
      width: 100%;
      padding-right: 20px;
      padding-bottom: 50px;
      position: relative;
      z-index: 0;

      @media screen and (max-width: 480px) { 
        margin: 0;
        display: inherit;
      }

      div {
        width: 80%;
      }

      ul {
        margin-right: 80px;
        list-style: none;
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;

        li {
          margin-bottom: 20px;
          position: relative;
          :before {
            content: '';
            width: 20px;
            height: 5px;
            margin-top: 10px;
            margin-left: -35px;
            background-color: #780600;
            position: absolute;
          }
          label {
            color: #2e2e2e;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
