import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Medicos from '../pages/Medicos';
import Pacientes from '../pages/Pacientes';
import Subespecialidades from '../pages/Subespecialidades';
import Servicos from '../pages/Servicos';
import QuemSomos from '../pages/QuemSomos';
import Pesquisa from '../pages/Pesquisa';
import EnvioDeExames from '../pages/EnvioDeExames';
import UtilidadePublica from '../pages/UtilidadePublica';
import Informacoes from '../pages/Informacoes';
import Contato from '../pages/Contato';
import Noticias from '../pages/Noticias';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/medicos" exact component={Medicos} />
      <Route path="/pacientes" exact component={Pacientes} />
      <Route path="/subespecialidades" exact component={Subespecialidades} />
      <Route path="/servicos" exact component={Servicos} />
      <Route path="/quem-somos" exact component={QuemSomos} />
      <Route path="/pesquisa" exact component={Pesquisa} />
      <Route path="/coleta-e-envio-de-exames" exact component={EnvioDeExames} />
      <Route path="/utilidade-publica" exact component={UtilidadePublica} />
      <Route path="/informacoes/:slug" component={Informacoes} />
      <Route path="/informacoes" exact component={Informacoes} />
      <Route path="/contato" exact component={Contato} />
      <Route path="/noticias" exact component={Noticias} />
    </Switch>
  );
}
