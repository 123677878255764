import React from 'react';

// styles
import { Container, Infos, Ade } from './styles';

export default function Header() {
  return (
    <>
      <Container>
        <Infos className="wrapper">
          <div className="links">
            <div className="item">
              <h3>O que fazemos</h3>
              <a href="/sobre">Subespecialidades</a>
              <a href="/sobre">Serviços</a>
              <a href="/sobre">Pesquisa</a>
            </div>

            <div className="item">
              <h3>O que atendemos</h3>
              <a href="/sobre">Aos Pacientes</a>
              <a href="/sobre">Aos Médicos</a>
              <a href="/sobre">Coleta e Envio de Exames</a>
            </div>

            <div className="item">
              <h3>Informações</h3>
              <a href="/sobre">Anatomia Patológica</a>
              <a href="/sobre">Significado do Laudo Anatomopatológico</a>
              <a href="/sobre">Exame de Biópsia</a>
              <a href="/sobre">Exame Citopatológico</a>
              <a href="/sobre">Envio de Exames ao Laboratório</a>
              <a href="/sobre">Exame de Revisão</a>
              <a href="/sobre">Tempo do Laudo Anatomopatológico</a>
              <a href="/sobre">Outros Exame Feitos</a>
              <a href="/sobre">Curiosidades</a>
              <a href="/sobre">O Que Significa o seu Diagnóstico?</a>
            </div>
          </div>
          
          <div className="logos">
            <div>
              <a href="/">
                <img
                  alt="Logo Patologia"
                  src={require('../../assets/PatoFooter.svg')}
                />
              </a>
            </div>
            <div className="bot">
              <a href="https://www.facebook.com/pages/Patologia%20Medica%20de%20Ponta%20Grossa/1317643834928703/" target="__blank">
                <img
                  alt="Logo Face"
                  src={require('../../assets/IconFace.svg')}
                />
              </a>
              <a href="https://www.instagram.com/explore/locations/1317643834928703/patologia-medica-de-ponta-grossa/" target="__blank">
                <img
                  alt="Logo Insta"
                  src={require('../../assets/Iconinsta.svg')}
                />
              </a>
            </div>
          </div>
        </Infos>

        <Ade>
          <div className="wrapper">
            <div>
              <label>
                Desenvolvido com{' '}
                <span role="img" aria-label="core">
                  ❤️
                </span>{' '}
                por
                <a
                  href="https://agenciaade.com.br/"
                >
                  {' '}
                  agenciaade.com.br
                </a>
              </label>
            </div>
          </div>
        </Ade>
      </Container>
      
    </>
  );
}
