import React from 'react';
import { Helmet } from 'react-helmet';

// components 
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// stlyes 
import { Container } from './styles';

// icons 
// import { FcNext } from 'react-icons/fc';

export default function Medicos() {
  return (
    <>
      <Helmet>
        <title>Utilidade Pública - Patologia Médica</title>
        <meta
          name="description"
          content="Excelência diagnóstica em  anatomia patológica."
        />
        <meta
          name="keywords"
          content="medicos, patologia, médica, consulta, doenças, atendimento"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header />

      <Container>
        <div className="wrapper">
          <div className="Med">
            <div>
              <h1>Utilidade Pública</h1>
              <p>
                Acesse o sistema “Medicina Celular” para mais informações sobre diagnósticos, cuidados, ajuda e etc.
              </p>

              <img alt="Medicina Celular" src={require('../../assets/mediciona-celular.png')} />

              <a href="/utilidade-publica" target="__blank">
                Clique aqui
                {/* <FcNext size={18} /> */}
              </a>
            </div>

            <div>
              <img alt="Medico" src={require('../../assets/utilidade.jpg')} />
            </div>
          </div>
        </div>
      </Container>

      <Duvidas />
  
      <InfosDeContato />

      <Footer />
    </>
  );
}
