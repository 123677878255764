import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';
import ReactHtmlParser from 'react-html-parser';
 
// api
import api from '../../services/api'; 

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// styles
import { Informacoes, Textos, Fotos } from './styles';

export default function EnvioDeExames() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=coleta-e-envio-de-exames')
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>Envio de Exames ao Laboratório | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
      <>
        <Helmet>
          <title>Envio de Exames ao Laboratório | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <main>
          <Informacoes className="wrapper">
            <h1>Envio de Exames ao Laboratório</h1>
            <h2>Como os Exames são enviados ao Laboratório</h2>

            <div className="grid">
              <Textos>           
                <div className="box">
                  <h3>Coleta</h3>
                  <p>{!!dados && dados.acf.coleta}</p>
                </div>

                <div className="box">
                  <h3>Requisição dos exames</h3>
                  <p>{!!dados && dados.acf.requisicao_dos_exames.texto}</p>
                
                  <ul>
                    {!!dados && dados.acf.requisicao_dos_exames.dados_claros_e_legiveis.map( (item, index) => (
                      <li key={index}>
                        <label>{item.dado}</label>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="box">
                  <h3>Acondicionamento do material de biópsia</h3>
                  <p>{!!dados && dados.acf.acondicionamento_do_material_de_biopsia.texto}</p>
                
                  <ul>
                    {!!dados && dados.acf.acondicionamento_do_material_de_biopsia.regras_basicas.map( (item, index) => (
                      <li key={index}>
                        <label>{item.regra}</label>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="box">
                  <h3>Fixação do material de biópsia</h3>
                  <p>{!!dados && dados.acf.fixacao_do_material_de_biopsia}</p>
                </div>

                <div className="box">
                  <h3>Como encaminhar uma amostra para exame citopatológico</h3>
                  {!!dados && ReactHtmlParser(dados.acf.como_encaminhar_uma_amostra_para_exame_citopatologico.texto)}
                
                  <ul>
                    {!!dados && dados.acf.como_encaminhar_uma_amostra_para_exame_citopatologico.liquidos.map( (item, index) => (
                      <li key={index}>
                        <label>{item.liquido}</label>
                      </li>
                    ))}
                  </ul>

                  <h4>Líquor:</h4>
                  <ul>
                    <li>Enviar ao laboratório logo após a coleta ou manter em geladeira por pouco tempo.</li>
                  </ul>
                </div>
              </Textos>

              <Fotos>
                <img src={require('../../assets/exames/exames_03.jpg')} alt="" />
                <img src={require('../../assets/exames/exames_02.jpg')} alt="" />
                <img src={require('../../assets/exames/exames_01.jpg')} alt="" />
              </Fotos>
            </div>
          </Informacoes>

          <Duvidas />
          
          <InfosDeContato />
        </main>
        
        <Footer />
      </>
    );
  }
}