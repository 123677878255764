import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';

// api
import ademail from '../../services/ademail';

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// icons
import { FcNext } from 'react-icons/fc';

// styles
import { Banner, Content } from './styles';

export default function Contato() {
  const [disabled, setDisabled] = useState(false);
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Mensagem: ${values.mensagem}</p>
      `;

      const mail = {
        to: 'contato@patologiamedica.com.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['adm@patologiamedica.com.br']),
        subject: 'Novo contato via site - Patologia Médica',
        message: body,
      };

      try {
        // Sucesso ao enviar
        setDisabled(true);
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
        setDisabled(false);
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Contato | Patologia Médica</title>
        <meta
          name="description"
          content="Excelência diagnóstica em  anatomia patológica."
        />
        <meta
          name="keywords"
          content="medicos, patologia, médica, consulta, doenças, atendimento"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header />

      <main>
        <Banner>
          <img
            alt="Contato - Patologia Médica"
            src={require('../../assets/banner_contato.jpg')}
          />
        </Banner>

        <Content className="wrapper">
          <div>
            <h1>Contato</h1>
            <p>Tem alguma dúvida ou gostaria de saber alguma informação?</p>

            <form onSubmit={formik.handleSubmit}>
              <div id="inputs">
                <div>
                  <label>Nome</label>
                  <input
                    type="text"
                    name="nome"
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>

                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                </div>

                <div>
                  <label>Telefone</label>
                  <Input
                    type="tel"
                    name="telefone"
                    mask={
                      formik.values.telefone.length <= 14
                        ? '(99) 9999-9999?'
                        : '(99) 99999-9999'
                    }
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    maskChar={null}
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefone}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                </div>
              </div>

              <label>Mensagem</label>
              <textarea
                name="mensagem"
                rows="6"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mensagem}
              />
              {formik.touched.mensagem && formik.errors.mensagem ? (
                <span>{formik.errors.mensagem}</span>
              ) : null}
              <button
                type="submit"
                className={disabled ? 'disable' : ''}
                disabled={disabled ? 'disable' : ''}
              >
                Enviar
                <FcNext size={18} />
              </button>
            </form>
          </div>

          <iframe
            title="Localização"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14451.493258775547!2d-50.1642032!3d-25.1061494!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf07de88895ecb961!2sPatologia%20M%C3%A9dica!5e0!3m2!1spt-BR!2sbr!4v1599429266841!5m2!1spt-BR!2sbr"
            width="100%"
            height="450"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Content>

        <Duvidas />

        <InfosDeContato />
      </main>

      <Footer />
    </>
  );
}
