import styled from 'styled-components';
import 'antd/es/notification/style/css';

// Banner
export const Banner = styled.section`
  position: relative;

  @media screen and (max-width: 480px) { 
    margin-top: 110px;
    padding-top: 0;
  }

  img {
    width: 100%;
    z-index: -1;
    position: relative;
    max-height: 420px;
    object-fit: cover;

    @media screen and (max-width: 480px) { 
      height: 120px;
    }
  }
`;

// Content
export const Content = styled.section`
  display: grid;
  padding: 60px 0 100px 0;
  grid-template-columns: 45% 50%;
  grid-gap: 5%;

  @media screen and (max-width: 480px) { 
    display: inherit;
  }

  h1 {
    font-weight: 800;
    font-size: 34px;
    color: #2e2e2e;
    position: relative;
    margin-top: 30px;
    margin-bottom: 0;

    @media screen and (max-width: 480px) { 
      font-size: 30px;
      margin-top: 0;
    }

    :before {
      content: '';
      width: 65%;
      height: 5px;
      margin: 0;
      background-color: #780600;
      position: absolute;
      z-index: 99;
      top: 50%;
      transform: translateY(60%);
      right: 0;
      /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }

  > p {
    font-size: 18px;
  }

  form {

    @media screen and (max-width: 480px) { 
      margin-bottom: 60px;
    }

    input, textarea {
      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #000;
      transition: all ease-in .3s;
      
      :focus {
        border-color: #780600;
      }
    }

    label {
      display: block;
      margin-bottom: 0;
      margin-top: 30px;
    }

    span {
      color: red;
      padding-top: 5px;
      display: block;
    }

    button {
      margin-left: 0;
      margin-right: auto;
      display: block;
      width: 120px;
      font-size: 20px;
      color: #2e2e2e;
      font-weight: 500;
      background: none;
      border: none;
      cursor: pointer;
      text-align: left;
      position: relative;
      margin-top: 35px;
      font-weight: bold;

      :before {
        content: '';
        width: 100%;
        background-color: #780600;
        height: 3px;
        position: absolute;
        margin-top: 34px;
        transition: all 0.6s;
        left: 0;
      }
      :hover {
        color: #780600;

        :before {
          width: 170px;
        }
        svg {
          right: -50px;
        }
      }
    }
    svg {
      margin-top: 7px;
      position: absolute;
      transition: all 0.6s;
      right: 0;

      polygon {
        fill: #780600;
      }
    }
  }
`;
