import styled from 'styled-components';

// Banner
export const Banner = styled.section`
  position: relative;

  @media screen and (max-width: 480px) { 
    margin-top: 110px;
    padding-top: 0;
  }

  img {
    width: 100%;
    z-index: -1;
    position: relative;
    max-height: 420px;
    object-fit: cover;
  }
`;

// Informacoes
export const Informacoes = styled.section`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 50% 40%;
  grid-gap: 10%;

  @media screen and (max-width: 480px) { 
    display: inherit;
  }
`;

// Textos
export const Textos = styled.div`
  h1 {
    font-weight: 800;
    font-size: 34px;
    color: #2e2e2e;
    position: relative;
    margin-top: 40px;

    :before {
      content: '';
      width: 70%;
      height: 5px;
      margin: 0;
      background-color: #780600;
      position: absolute;
      z-index: 99;
      top: 50%;
      transform: translate(60%, 20%);
      /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
  }

  .box {
    background: #F5F5F5;
    padding: 10%;
    margin-top: 40px;
    margin-left: -10%;
    border-radius: 10px;

    @media screen and (max-width: 480px) { 
      margin-left: 0;
    }

    ul {
      padding: 0 0 0 35px;
      margin: 0;

      li {
        font-size: 14px;
        list-style: none;
        margin-bottom: 7.5px;
        position: relative;

        :before {
          content: '';
          width: 20px;
          height: 5px;
          margin: 0;
          background-color: #780600;
          position: absolute;
          z-index: 99;
          /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
          top: 8.5px;
          left: -35px;
        }
      }
    }

    p:last-of-type {
      margin: 30px 0;
    }

    img {
      width: 100%;
    }
  }
`;

// Valores
export const Valores = styled.div`
  background: #F5F5F5;
  padding: 12.5%;
  margin-top: -140px;
  border-radius: 10px;
  height: 100%;

  @media screen and (max-width: 480px) { 
    margin-top: 30px;
    margin-bottom: 80px;
  }

  > div {
    margin-bottom: 150px;

    @media screen and (max-width: 480px) { 
      margin-bottom: 30px;
    }

    img {
      height: 75px;
    }

    h3 {
      font-weight: 800;
      font-size: 28px;
      margin: 19px 0 55px 0;
      position: relative;

      :before {
        content: '';
        width: 40%;
        height: 5px;
        margin: 0;
        background-color: #780600;
        position: absolute;
        z-index: 99;
        bottom: -15px;
        /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
      }
    }

    .linha {
      width: 40%;
      height: 5px;
      margin: 35px 0;
      background-color: #780600;
      z-index: 99;
      display: block;
    }
    
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;
    }

    ul {
      padding: 0 0 0 30px;
      margin: 40px 0 0 0;

      li {
        font-size: 14px;
        list-style: none;
        margin-bottom: 7.5px;
        position: relative;

        :before {
          content: '';
          width: 20px;
          height: 5px;
          margin: 0;
          background-color: #780600;
          position: absolute;
          z-index: 99;
          /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
          top: 8.5px;
          left: -35px;
        }
      }
    }
  }
`;