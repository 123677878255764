import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';

// api
import api from '../../services/api'; 

// components 
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// stlyes 
import { Container } from './styles';

export default function Medicos() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=aos-medicos')
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>Aos Médicos | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
      <>
        <Helmet>
          <title>Aos Médicos | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <Container>
          <div className="wrapper">
            <div className="Med">
              <div>
                <h1>Aos Médicos</h1>
                <p>
                  {!!dados && dados.acf.descricao}
                </p>
              </div>
              <div>
                <img alt="Medico" src={require('../../assets/Med.jpg')} />
              </div>
            </div>
          </div>
        </Container>

        <Duvidas />
    
        <InfosDeContato />

        <Footer />
      </>
    );
  }
}