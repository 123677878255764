import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Sub } from './styles';
import ReactLoading from 'react-loading';

// api
import api from '../../services/api';

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

export default function Subespecialidades() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=subespecialidades', {})
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>Subespecialidades | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
      <>
        <Helmet>
          <title>Subespecialidades | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <Container>
          <img alt="Banner Médico" src={require('../../assets/fotolia.jpg')} />
        </Container>
        <Sub>
          <div className="wrapper">
            <div className="Sub">
              <div>
                <h1>Subespecialides</h1>
              </div>
              <div className="Box">
                <div>
                  <ul>
                    {!!dados && dados.acf.lista.map( (item, index) => (
                      <li key={index}>
                        <label>{item.subespecialidade}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Sub>
        
        <Duvidas />

        <InfosDeContato />

        <Footer />
      </>
    );
  }
}
