import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 100px;
  position: relative;

  @media screen and (max-width: 480px) {
    overflow-x: hidden;

    .slick-list {
      height: 250px;
    }
  }

  .Slide {
    height: 550px;
    position: relative;

    @media screen and (max-width: 480px) {
      height: 250px;

      .wrapper {
        display: none;
      }
    }

    img {
      width: 100%;
      position: relative;
      object-fit: cover;
      height: 100%;
      z-index: -9;
    }
    .Box {
      width: 500px;
      padding: 40px;
      background-color: white;
      margin-top: -400px;
      align-items: center;

      @media screen and (max-width: 480px) {
        display: none;
      }

      h1 {
        font-size: 25px;
        line-height: 30px;
        font-weight: 500;
        color: #2e2e2e;
      }
      a {
        color: #2e2e2e;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        transition: all 0.3s;
        :before {
          content: '';
          width: 105%;
          background-color: #780600;
          height: 3px;
          position: absolute;
          margin-top: 27px;
          transition: all 0.3s;
        }
        :hover {
          color: #780600;

          svg {
            width: 320px;
          }
          :before {
            width: 174%;
          }
        }
      }
      svg {
        margin-top: 4px;
        position: absolute;
        transition: all 0.3s;
        polygon {
          fill: #780600;
        }
      }
    }
  }

  .slick-dots {
    @media screen and (max-width: 480px) {
      display: none !important;
    }
  }
`;

export const OqFazemos = styled.section`
  position: relative;
  margin-bottom: 100px;

  @media screen and (max-width: 480px) {
    padding-bottom: 75px;
    margin-bottom: 0;
  }

  .Fundo {
    position: absolute;
    background-color: #f5f5f5;
    height: 600px;
    z-index: -999;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 100%;
    }
  }

  .linha {
    width: 940px;
    z-index: 99 !important;
    margin-top: -50px !important;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    /* grid-auto-rows: 300px; */
    grid-gap: 20px;
    grid-template-columns: repeat(3, 31.8%);

    @media screen and (max-width: 480px) {
      grid-template-columns: 100%;
      margin-top: 0;
      width: 100%;
    }

    .Box {
      width: 100%;
      background: white;
      transition: all 0.6s;
      padding-top: 20px;
      padding-left: 20px;
      height: 430px;
      position: relative;

      h2 {
        margin-top: 14px;
        font-weight: 600;
        font-size: 18px;
        color: #2e2e2e;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #2e2e2e;
      }
      button {
        color: #2e2e2e;
        font-weight: 600;
        font-size: 16px;
        position: relative;
        transition: all 0.6s;
        background: none;
        border: none;
        position: absolute;
        bottom: 30px;

        :before {
          content: '';
          width: 113%;
          background-color: #780600;
          height: 3px;
          position: absolute;
          margin-top: 27px;
          transition: all 0.6s;
        }
      }
      svg {
        margin-top: 4px;
        position: absolute;
        transition: all 0.6s;
        polygon {
          fill: #780600;
        }
      }
      :hover {
        background: #780600;
        margin-top: -50px;
        padding-top: 70px;
        height: 480px;

        h2 {
          color: white;
        }
        p {
          color: white;
        }
        a {
          color: white;
          :before {
            width: 233%;
            background-color: white;
          }
        }
        button {
          color: white;

          :before {
            background: #fff;
          }
        }
        svg {
          width: 220px;
          polygon {
            fill: white;
          }
        }
        img {
          filter: brightness(350%);
        }
      }
    }
  }
`;

export const Sobre = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: -10px;
  margin: 0 auto;
  max-width: 1090px;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    margin-top: 0;
    grid-gap: 0;
    max-width: 100%;
  }

  .item1 {
    grid-row: 1 / 3;
    img {
      width: 100%;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  .cientista {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  .itemMedica {
    padding: 20px;
    background: #780600;
    height: 100%;

    @media screen and (max-width: 480px) {
      height: auto;
      padding: 40px 7.5% 60px 7.5%;
    }

    h2 {
      color: white;
      font-size: 24px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 40px;
      :before {
        content: '';
        width: 80px;
        background-color: white;
        height: 3px;
        position: absolute;
        margin-top: 68px;
      }
    }
    p {
      color: white;
      font-size: 13px;
      font-weight: 400;
    }
    a {
      color: white;
      font-weight: 500;
      font-size: 13px;
      position: relative;
      transition: all 0.6s;
      :before {
        content: '';
        width: 106%;
        background-color: white;
        height: 3px;
        position: absolute;
        margin-top: 27px;
        transition: all 0.6s;
      }
      :hover {
        :before {
          width: 141%;
        }
        svg {
          width: 170px;
        }
      }
    }
    svg {
      margin-top: 4px;
      position: absolute;
      transition: all 0.6s;
      polygon {
        fill: white;
      }
    }
  }
  .itemClinica {
    padding: 20px;
    background: #f5f5f5;
    height: 100%;

    @media screen and (max-width: 480px) {
      height: auto;
      padding: 40px 7.5% 60px 7.5%;
    }

    h2 {
      color: #2e2e2e;
      font-size: 24px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 40px;
      :before {
        content: '';
        width: 80px;
        background-color: #780600;
        height: 3px;
        position: absolute;
        margin-top: 42px;
      }
    }
    p {
      color: #2e2e2e;
      font-size: 13px;
      width: 320px;
      font-weight: 500;
    }
    a {
      color: #2e2e2e;
      font-weight: 600;
      font-size: 13px;
      position: relative;
      transition: all 0.6s;
      :before {
        content: '';
        width: 106%;
        background-color: #780600;
        height: 3px;
        position: absolute;
        margin-top: 27px;
        transition: all 0.6s;
      }
      :hover {
        :before {
          width: 175%;
        }
        svg {
          width: 260px;
        }
      }
    }
    svg {
      margin-top: 4px;
      position: absolute;
      transition: all 0.6s;
      polygon {
        fill: #780600;
      }
    }
  }
`;

export const Doenças = styled.section`
  position: relative;
  margin: 100px 0;

  @media screen and (max-width: 480px) {
    margin: 0 0 80px 0;
    padding-bottom: 40px;
  }

  .Fundo {
    position: absolute;
    background-color: #f5f5f5;
    height: 610px;
    z-index: -999;
    width: 1000px;

    @media screen and (max-width: 480px) {
      width: 100%;
      height: 100%;
    }
  }
  .divisao {
    padding-top: 70px;
    display: flex;

    @media screen and (max-width: 480px) {
      display: grid;
      grid-template-columns: 100%;
      padding-top: 0;
    }

    img {
      width: 900px;
      object-fit: cover;
      height: 590px;
      margin-left: 30px;

      @media screen and (max-width: 480px) {
        width: 100%;
        margin-left: 0;
        grid-row: 1/2;
        height: 300px;
      }
    }
    .Box {
      background: white;
      width: 600px;
      padding: 20px;
      padding-left: 190px;

      @media screen and (max-width: 480px) {
        width: 100%;
        padding: 30px 5%;
      }

      h2 {
        font-weight: 700;
        font-size: 30px;
        color: #780600;
        margin-bottom: 0px;
      }
      label {
        color: #2e2e2e;
        font-weight: 500;
        font-size: 15.5px;

        :before {
          content: '';
          width: 100px;
          background-color: #780600;
          height: 5px;
          position: absolute;
          margin-top: 65px;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }
      }
    }
    ul {
      padding-left: 190px;
      list-style: none;
      font-size: 14px;
      color: #2e2e2e;
      font-weight: 500;
      margin-top: 22px;

      @media screen and (max-width: 480px) {
        padding: 0 5%;
      }

      li {
        margin-bottom: 5px;
      }
    }
    .Mais {
      font-weight: bold;
      font-size: 16px;
      color: #780600;
      margin-bottom: 0px;
      padding-left: 190px;
      transition: all ease 0.7s;
      :hover{
        color: #2e2e2e;
      }
    }
  }
  :after {
    content: '';
    width: 70%;
    background-color: #780600;
    height: 5px;
    position: absolute;
    margin-top: -100px;
    margin-left: 190px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;

// Blog
export const Blog = styled.section`
  margin: 80px 0 -45px 0;

  @media screen and (max-width: 480px) {
    background-color: #f5f5f5;
    margin-bottom: -110px;
  }

  .blog-top {
    justify-content: space-between;
    display: flex;

    @media screen and (max-width: 480px) {
      padding-top: 40px;
    }

    h2 {
      font-weight: 700;
      font-size: 25px;
      color: #2e2e2e;

      :before {
        content: '';
        width: 600px;
        background-color: #780600;
        height: 5px;
        position: absolute;
        margin-top: 20px;
        transition: all 0.6s;
        margin-left: 240px;

        @media screen and (max-width: 480px) {
          width: 100%;
          display: none;
        }
      }
    }
    a {
      font-size: 20px;
      color: #2e2e2e;
      width: 210px;
      font-weight: 500;

      @media screen and (max-width: 480px) {
        width: 180px;
        padding-top: 20px;
      }

      :before {
        content: '';
        width: 120px;
        background-color: #780600;
        height: 3px;
        position: absolute;
        margin-top: 34px;
        transition: all 0.6s;
      }
      :hover {
        font-weight: 600;
        :before {
          width: 200px;
        }
        svg {
          width: 173px;
        }
      }
    }
    svg {
      margin-top: 7px;
      position: absolute;
      transition: all 0.6s;
      polygon {
        fill: #780600;
      }
    }
  }
  .Fundo {
    position: absolute;
    background-color: #f5f5f5;
    height: 370px;
    z-index: -999;
    margin-top: 200px;
    width: 100%;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  .blog-bottom {
    margin-top: 15px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 480px) {
      width: 100%;
      grid-template-columns: 100%;
    }

    .noticia {
      background: white;
      transition: all 0.3s;
      padding-bottom: 30px;

      :hover {
        background: #780600;
        img {
          filter: contrast(110%);
        }
        a,
        label,
        p,
        h3 {
          color: white;
        }
        a:before {
          width: 230px;
          background-color: white;
        }
        svg {
          width: 215px;
          polygon {
            fill: white;
          }
        }

        @media screen and (max-width: 480px) {
          /* background: initial; */
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        margin-bottom: 10px;
        height: 200px;
      }
      label {
        font-weight: 500;
        margin-left: 10px;
        color: #2e2e2e;
      }
      h3 {
        font-weight: 700;
        margin-left: 10px;
        font-size: 18px;
        margin-bottom: 10px;
        color: #2e2e2e;
        margin-top: 10px;
      }
      p {
        font-weight: 400;
        margin-left: 10px;
        font-size: 14px;
        margin-right: 10px;
        line-height: 18px;
        color: #2e2e2e;
        height: 90px;

        @media screen and (max-width: 480px) {
          height: initial;
        }
      }
      a {
        margin-left: 10px;
        font-size: 14px;
        color: #2e2e2e;
        width: 280px;
        font-weight: 500;

        @media screen and (max-width: 480px) {
          width: 100%;
          height: 100%;
          margin-left: 0;

          :last-of-type {
            margin-left: 10px;
          }
        }

        :before {
          content: '';
          width: 125px;
          background-color: #780600;
          height: 3px;
          position: absolute;
          margin-top: 28px;
          transition: all 0.6s;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }
        :hover {
          color: white;
          font-weight: 600;
          :before {
            width: 230px;
            background-color: white;
          }
          svg {
            width: 215px;
            polygon {
              fill: white;
            }
          }
        }
      }
      svg {
        margin-top: 3px;
        position: absolute;
        transition: all 0.6s;
        polygon {
          fill: #780600;
        }
      }
    }
  }
`;
