import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';
import ReactHtmlParser from 'react-html-parser';
import { Collapse } from 'antd';
import slugify from 'react-slugify';

// api
import api from '../../services/api'; 

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// styles
import { Banner, Container } from './styles';

export default function Informacoes( {match} ) {
  const Panel = Collapse.Panel;
  const customPanelStyle = {
    background: '#F5F5F5',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
  };

  const slug = match.params.slug;

  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=informacoes')
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>Informações | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
    <>
      <Helmet>
        <title>Informações | Patologia Médica</title>
        <meta
          name="description"
          content="Excelência diagnóstica em  anatomia patológica."
        />
        <meta
          name="keywords"
          content="medicos, patologia, médica, consulta, doenças, atendimento"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header />

      <main>
        <Banner>
          <img alt="Banner pesquisa - Patologia Médica" src={require('../../assets/banner_Informacoes.jpg')} />
        </Banner>

        <Container className="wrapper">
          <h1>Informações</h1>
          <p>Informações, curiosidades e esclarecimento sobre a Medicina Patológica.</p>

          <Collapse accordion defaultActiveKey={[slug]} bordered={false} className="collapse">
            {!!dados && dados.acf.informacoes_curiosidades.map( item => (
              <Panel style={customPanelStyle} header={item.nome} key={slugify(item.nome)}>
                <div id={slugify(item.nome)}>
                  {ReactHtmlParser(item.texto)}
                </div>
              </Panel>
            ))}
          </Collapse>
        </Container>

        <Duvidas />
        
        <InfosDeContato />
      </main>
      
      <Footer />
    </>
  );
}
}
