import styled from 'styled-components';
export const Container = styled.section`
  padding-top: 150px;
  position: relative;
  margin-bottom: 100px;

  @media screen and (max-width: 480px) { 
    margin-bottom: 50px;
  }

  .Med {
    display: flex;

    @media screen and (max-width: 480px) { 
      display: inherit;
    }

    > div:first-of-type {
      width: 880px;
      padding: 30px 60px 30px 0px;

      @media screen and (max-width: 480px) { 
        width: 100%;
        padding: 20px 0;
      }
    
      h1 {
        font-weight: 800;
        font-size: 30px;
        color: #2e2e2e;
        position: relative;

        :after {
          content: '';
          width: 400px;
          height: 5px;
          margin-top: 25px;
          margin-left: 10px;
          background-color: #780600;
          position: absolute;

          @media screen and (max-width: 480px) { 
            display: none;
          }
        }
      }
      h2 {
        font-weight: 600;
        font-size: 20px;
        color: #2e2e2e;
        margin-top: 30px;

        @media screen and (max-width: 480px) { 
          margin-top: 15px;
          line-height: 120%;
        }
      }
      p {
        color: #2e2e2e;
        font-size: 14px;
        line-height: 21px;
      }
    }
    img {
      position: relative;
      margin-top: 55px;
      width: 100%;

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }
`;