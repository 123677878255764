import React from 'react';
// Styles
import { Container } from './styles';

export default function InfosDeContato() {
  return (
    <>
      <Container>
        <div className="wrapper">
          <div className="elementos">
            <div className="atendimento">
              <img
                alt="Atendimento"
                src={require('../../assets/IconAtendimento.svg')}
              />
              <h3>Atendimento</h3>
              <p>de seg. a sex. das 07:30h às 12h e das 13:30h às 18h</p>
            </div>
            <div>
              <a href="https://www.google.com/maps/place/Patologia+M%C3%A9dica/@-25.1061494,-50.1642032,15z/data=!4m2!3m1!1s0x0:0xf07de88895ecb961?sa=X&ved=2ahUKEwii1L-lmdrrAhWyGbkGHe4NDlAQ_BIwCnoECBQQCA">
                <img alt="Endereço" src={require('../../assets/IconMap.svg')} />
                <h3>
                  R. <strong>Prudente de Morais, 361</strong><br/>Vila Estrela,
                  Ponta Grossa - PR
                </h3>
              </a>
            </div>
            <div>
              <a href="mailto:contato@patologiamedica.com.br">
                <img
                  alt="Email"
                  src={require('../../assets/IconEmail.svg')}
                />
                <h3>
                  contato@<strong>patologiamedica</strong>.com.br
                </h3>
              </a>
            </div>
            <div>
              <a href="tel:+554230288117">
                <img
                  alt="Telefone"
                  src={require('../../assets/IconTelefone.svg')}
                />
                <h3>
                  42 <strong>3028-8117</strong>
                </h3>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
