import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import slugify from 'react-slugify';

// api
import api from '../../services/api';

// icons
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';

// styles
import { Container } from './styles';

export default function Header() {
  // const
  const [informacoes, setInformacoes] = useState(null);

  // useEffect
  useEffect(() => {
    async function getInformacoes() {
      await api.get('/pages?slug=informacoes').then((response) => {
        setInformacoes(response.data[0]);
      });
    }
    getInformacoes();
  }, []);

  // State
  const [seMenuAberto, setSeMenuAberto] = useState(false);

  // Abrir e fechar o menu
  function abreFechaMenu() {
    setSeMenuAberto(!seMenuAberto);
    document.body.classList.toggle('is-active');
  }

  const oqfazemos = (
    <Menu>
      <Menu.Item>
        <a href="/subespecialidades">Subespecialidades</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/servicos">Serviços</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/pesquisa">Pesquisa</a>
      </Menu.Item>
    </Menu>
  );

  const oqatendemos = (
    <Menu>
      <Menu.Item>
        <a href="/pacientes">Aos Pacientes</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/medicos">Aos Médicos</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/coleta-e-envio-de-exames">Coleta e Envio de Exames</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/utilidade-publica">Utilidade Pública</a>
      </Menu.Item>
    </Menu>
  );

  const infos = (
    <Menu>
      {!!informacoes &&
        informacoes.acf.informacoes_curiosidades.map((item, index) => (
          <Menu.Item key={index}>
            <a href={`/informacoes/${slugify(item.nome)}`}>{item.nome}</a>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      <Container>
        <div className="header-top">
          <div className="wrapper">
            <label>
              <strong>Atendimento:</strong> Seg. a sex. das 07:30 às 18:00 horas{' '}
            </label>
            <div className="Line0" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/Patologia+M%C3%A9dica/@-25.1061494,-50.1642032,15z/data=!4m12!1m6!3m5!1s0x0:0xf07de88895ecb961!2sPatologia+M%C3%A9dica!8m2!3d-25.1060564!4d-50.1642333!3m4!1s0x0:0xf07de88895ecb961!8m2!3d-25.1060564!4d-50.1642333"
            >
              R. <strong>Prudente de Morais, 361</strong> <br />
              Vila Estrela, Ponta Grossa - PR
            </a>
            <div className="Line0" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contato@patologiamedica.com.br"
            >
              contato@<strong>patologiamedica</strong>.com.br
            </a>
            <div className="Line0" />
            <div className="whatsapp">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/554230288117"
              >
                <AiOutlineWhatsApp color="#25D366" size={18} />
                42 <strong> 3028-8117</strong>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/5542991438242"
              >
                <AiOutlineWhatsApp color="#25D366" size={18} />
                42 <strong>99143-8242</strong>
              </a>
            </div>

            <a href="http://laudos.patologiamedica.com.br/" id="BotaoCliente">
              RESULTADOS DE EXAMES
            </a>
          </div>
        </div>
        <div className="header-bot">
          <div className="wrapper">
            <a href="/">
              <img
                alt="Logo Patologia"
                src={require('../../assets/logoPatologia.svg')}
              ></img>
            </a>
            <a href="http://laudos.patologiamedica.com.br/" className="exames">
              RESULTADOS DE EXAMES
            </a>
            <button
              className={`hamburger hamburger--collapse ${
                seMenuAberto && 'is-active'
              }`}
              type="button"
              onClick={abreFechaMenu}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>

            <nav className={`navigation ${seMenuAberto && 'is-active'}`}>
              <ul>
                <li>
                  <a href="/quem-somos">Quem Somos</a>
                </li>
                <li id="oqf">
                  <Dropdown overlay={oqfazemos}>
                    <a
                      href="/"
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      O que fazemos <FiChevronDown size={18} />
                    </a>
                  </Dropdown>
                </li>
                <li>
                  {' '}
                  <Dropdown overlay={oqatendemos}>
                    <a
                      href="/"
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      O que atendemos <FiChevronDown size={18} />
                    </a>
                  </Dropdown>
                </li>
                <li>
                  {' '}
                  <Dropdown overlay={infos}>
                    <a
                      href="/"
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Informações <FiChevronDown size={18} />
                    </a>
                  </Dropdown>
                </li>
                <li>
                  <a href="/contato">Contato</a>
                </li>
                <a
                  href="http://laudos.patologiamedica.com.br/"
                  className="BotaoClienteMobile"
                >
                  RESULTADOS DE EXAMES
                </a>
              </ul>
            </nav>
          </div>
        </div>
      </Container>
    </>
  );
}
