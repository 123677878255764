import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #ffffff;
    overflow-x: hidden;
    font-family: "Work Sans";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    &.is-active {
      @media screen and (max-width: 480px) {
        overflow: hidden;
      }
    }
  }

  .wrapper {
    width: 95%;
    max-width: 1170px;
    margin: 0 auto 0 auto;

    @media screen and (max-width: 480px) {
      max-width: 90%;
      width: 90%;
    }
  }

  .ant-dropdown {
    @media screen and (max-width: 480px) {
      min-width: 100% !important;
      width: 100%;
      max-width: 100%;
      left: 0 !important;
    }
  }

  .ant-dropdown-menu {
    background-color: #780600;
    padding: 20px;
    border-radius:  3px;
    box-shadow: none;

    @media screen and (max-width: 480px) {
      min-width: 90% !important;
      width: 90%;
      max-width: 90%;
      left: 5% !important;
      background: #fff;
    }
  }
  .ant-dropdown-menu-item {
    a {
      color: white;
      font-weight: 400;
      :hover {
        background-color: white;
        color: #780600;
      }

      @media screen and (max-width: 480px) {
        color: #780600;
      }
    }
  }

  .slick-dots li.slick-active button:before {
    color: #780600;
    margin-left: 480px;
    margin-top: -180px;
    transform: scale(2.5);
    opacity: 1;
  }

  .slick-dots li button:before {
    color: #780600; 
    opacity: 0.5;
    margin-left: 480px;
    margin-top: -180px;
    transform: scale(2.5);
  }
  .slick-slider {
    width: 102%;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
