import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 60px;
  background: #F5F5F5;

  .elementos {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 480px) { 
      width: 100%;
      grid-template-columns: 100%;
      grid-gap: 30px;
    }

    > div {
      padding: 20px;
      img {
        margin: auto;
        display: block;
        transition: all 0.3s;
        :hover {
          filter: brightness(150%);
        }
      }
      h3 {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #2e2e2e;
        line-height: 19px;
        transition: all 0.3s;
        :hover {
          opacity: 0.5;
        }
      }
      p {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #2e2e2e;
        transition: all 0.3s;
        :hover {
          opacity: 0.5;
        }
      }
      a {
        text-align: center;
      }
    }
  }

  .atendimento h3 {
    margin-bottom: 0;
    font-weight: bold !important;
  }
`;
