import styled from 'styled-components';

export const Container = styled.section`

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 120px;
  }
  h2 {
    width: 390px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 25px;
    line-height: 30px;
    color: #2e2e2e;

    @media screen and (max-width: 480px) { 
      width: 100%;
      font-size: 20px;
      text-align: center;
    }
  }
  a {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 128px;
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 500;
    :before {
      content: '';
      width: 120px;
      background-color: #780600;
      height: 3px;
      position: absolute;
      margin-top: 34px;
      transition: all 0.6s;
    }
    :hover {
      :before {
        width: 330px;
        margin-left: -110px;
      }
      svg {
        width: 214px;
      }
    }
  }
  svg {
    margin-top: 7px;
    position: absolute;
    transition: all 0.6s;
    polygon {
      fill: #780600;
    }
  }
`;
