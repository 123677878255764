import styled from 'styled-components';
export const Container = styled.section`
  padding-top: 150px;
  position: relative;
  margin-bottom: 80px;

  @media screen and (max-width: 480px) { 
    padding-top: 120px;
  }

  .Med {
    display: flex;

    @media screen and (max-width: 480px) { 
      display: inherit;
    }

    > div:first-of-type {
      width: 59%;
      padding: 50px;

      @media screen and (max-width: 480px) { 
        width: 100%;
        padding: 40px 0;
      }
      
      h1 {
        font-weight: 700;
        font-size: 30px;
        color: #2e2e2e;
        position: relative;
        :after {
          content: '';
          width: 167px;
          height: 5px;
          margin-top: 25px;
          margin-left: 10px;
          background-color: #780600;
          position: absolute;

          @media screen and (max-width: 480px) { 
            display: none;
          }
        }
      }
      p {
        color: #2e2e2e;
        font-size: 14px;
      }
    }
    img {
      position: relative;
      width: 100%;
    }
  }
`;
export const Duvidas = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 120px;
  }
  h2 {
    width: 390px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 25px;
    line-height: 30px;
    color: #2e2e2e;
  }
  a {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 128px;
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 500;
    :before {
      content: '';
      width: 120px;
      background-color: #780600;
      height: 3px;
      position: absolute;
      margin-top: 34px;
      transition: all 0.6s;
    }
    :hover {
      :before {
        width: 330px;
        margin-left: -110px;
      }
      svg {
        width: 214px;
      }
    }
  }
  svg {
    margin-top: 7px;
    position: absolute;
    transition: all 0.6s;
    polygon {
      fill: #780600;
    }
  }
`;
