import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 150px;
  position: relative;
  margin-bottom: 80px;

  @media screen and (max-width: 480px) { 
    padding-top: 120px;
  }
  
  h1 {
    font-weight: 700;
    font-size: 30px;
    color: #2e2e2e;
    position: relative;

    @media screen and (max-width: 480px) { 
      padding-top: 40px;
    }

    :after {
      content: '';
      width: 167px;
      height: 5px;
      margin-top: 25px;
      margin-left: 10px;
      background-color: #780600;
      position: absolute;

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }

  .grid {
    margin-top: 15px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 480px) { 
      width: 100%;
      grid-template-columns: 100%;
    }

    .noticia {
      background: white;
      transition: all 0.3s;
      padding-bottom: 30px;

      :hover {
        background: #780600;
        img {
          filter: contrast(110%);
        }
        a,
        label,
        p,
        h3 {
          color: white;
        }
        a:before {
          width: 230px;
          background-color: white;
        }
        svg {
          width: 215px;
          polygon {
            fill: white;
          }
        }

        @media screen and (max-width: 480px) {
          /* background: initial; */
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        margin-bottom: 10px;
        height: 200px;
      }
      label {
        font-weight: 500;
        margin-left: 10px;
        color: #2e2e2e;
      }
      h3 {
        font-weight: 700;
        margin-left: 10px;
        font-size: 18px;
        margin-bottom: 10px;
        color: #2e2e2e;
        margin-top: 10px;
      }
      p {
        font-weight: 400;
        margin-left: 10px;
        font-size: 14px;
        margin-right: 10px;
        line-height: 18px;
        color: #2e2e2e;
        height: 90px;

        @media screen and (max-width: 480px) {
          height: initial;
        }
      }
      a {
        margin-left: 10px;
        font-size: 14px;
        color: #2e2e2e;
        width: 280px;
        font-weight: 500;

        @media screen and (max-width: 480px) {
          width: 100%;
          height: 100%;
          margin-left: 0;

          :last-of-type {
            margin-left: 10px;
          }
        }

        :before {
          content: '';
          width: 125px;
          background-color: #780600;
          height: 3px;
          position: absolute;
          margin-top: 28px;
          transition: all 0.6s;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }
        :hover {
          color: white;
          font-weight: 600;
          :before {
            width: 230px;
            background-color: white;
          }
          svg {
            width: 215px;
            polygon {
              fill: white;
            }
          }
        }
      }
      svg {
        margin-top: 3px;
        position: absolute;
        transition: all 0.6s;
        polygon {
          fill: #780600;
        }
      }
    }
  }
`;
