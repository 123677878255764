import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';

// api
import api from '../../services/api';

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// styles
import { Banner, Informacoes, Textos, Valores, Principios } from './styles';

export default function QuemSomos() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=quem-somos', {})
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>QuemSomos | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
      <>
        <Helmet>
          <title>QuemSomos | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <main>
          <Banner>
            <img alt="Banner quem somos - Patologia Médica" src={require('../../assets/quemSomos/quem-somos.jpg')} />
          </Banner>

          <Informacoes className="wrapper">
            <Textos>
              <div className="sobre">
                <h1>Quem somos</h1>

                <div>
                  <p>{!!dados && dados.acf.quem_somos}</p>
                </div>
              </div>

              <div className="equipe">
                <h2>Equipe Médica</h2>

                {!!dados && dados.acf.equipe_medica.map( (medico, index) => (
                  <div key={index} className="medico">
                    <img src={medico.foto} alt="" />

                    <div className="descricao">
                      <h3>{medico.nome}</h3>
                    
                      <div>
                        {ReactHtmlParser(medico.descricao)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Textos>

            <Valores>
              <div>
                <img src={require('../../assets/quemSomos/missao.svg')} alt="" />
                <h3>Missão</h3>
                <div>
                  <p>{!!dados && dados.acf.identidade_organizacional.missao}</p>
                </div>
              </div>

              <div>
                <img src={require('../../assets/quemSomos/visao.svg')} alt="" />
                <h3>Visão</h3>
                <div>
                  <p>{!!dados && dados.acf.identidade_organizacional.visao}</p>
                </div>
              </div>
            </Valores>
          </Informacoes>

          <Principios>
            <img src={require('../../assets/quemSomos/principios.jpg')} alt="" />

            <div>
              <h2>Princípios, Orientação e Normas</h2>

              <ul>
                {!!dados && dados.acf.principios_orientacao_e_normas.map( (item, index) => (
                  <li key={index}>{item.principio}</li>
                ))}
              </ul>
            </div>
          </Principios>

          <Duvidas />
          
          <InfosDeContato />
        </main>
        
        <Footer />
      </>
    );
  }
}