import styled from 'styled-components';

// Banner
export const Banner = styled.section`
  position: relative;

  @media screen and (max-width: 480px) { 
    margin-top: 110px;
    padding-top: 0;
  }

  img {
    width: 100%;
    z-index: -1;
    position: relative;
    max-height: 420px;
    object-fit: cover;

    @media screen and (max-width: 480px) { 
      height: 120px;
    }
  }
`;

// Container
export const Container = styled.section`
  h1 {
    font-weight: 800;
    font-size: 34px;
    color: #2e2e2e;
    position: relative;
    margin-top: 60px;
    margin-bottom: 0;

    @media screen and (max-width: 480px) { 
      font-size: 30px;
      margin-top: 40px;
    }

    :before {
      content: '';
      width: 80%;
      height: 5px;
      margin: 0;
      background-color: #780600;
      position: absolute;
      z-index: 99;
      top: 50%;
      transform: translateY(60%);
      right: 0;
      /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }

  > p {
    font-size: 18px;
  }

  .collapse {
    margin: 50px 0 100px 0;
    background: none;

    .ant-collapse-header {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-collapse-content-box {
      padding: 20px 40px 40px 40px;
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 5%;

      @media screen and (max-width: 480px) { 
        display: inherit;
      }
      
      /* img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      } */

      h3 {
        font-size: 20px;
      }
    }
  }
`;
