import 'antd/dist/antd.css';
import 'hamburgers/dist/hamburgers.min.css';
import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  background: white;
  position: fixed;
  z-index: 999;
  top: 0;

  .hamburger {
    z-index: 9;
    display: none;
    padding-right: 0;

    @media screen and (max-width: 480px) {
      display: inherit;
    }
  }
  .hamburger .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before,
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:after,
  .hamburger.is-active .hamburger-inner:before {
    background-color: #fff;
  }

  .header-top {
    background: #ededed;
    width: 100%;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 480px) {
      padding: 7px 0;
      /* text-align: center; */
    }

    .wrapper {
      display: flex;
      align-items: center;
      height: 60px;
      justify-content: space-between;

      @media screen and (max-width: 480px) {
        display: inherit;
        height: fit-content;
        text-align: center;
      }

      a {
        font-size: 12px;
        text-decoration: none;
        color: #2e2e2e;

        :hover {
          color: #780600;
        }

        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      #BotaoCliente {
        border: 1px solid #780600;
        padding: 5px 15px;
        border-radius: 5px;
        transition: all 0.3s;
        color: #780600;
        font-weight: 500;
        margin-left: 5px;
        :hover {
          background: #780600;
          color: white;
          opacity: 1;
        }
        @media (max-width: 600px) {
          display: none;
        }
      }
      label {
        font-size: 12px;
        font-weight: 400;
      }
      .Line0 {
        position: relative;
        width: 1px;
        background: #780600;
        height: 15px;
        margin-left: 5px;
        margin-right: 5px;

        @media (max-width: 600px) {
          display: none;
        }
      }

      .whatsapp {
        padding: 10px 0;

        @media (max-width: 600px) {
          display: flex;
          justify-content: space-evenly;
        }

        a {
          display: flex;
          gap: 3px;
          padding: 2px 0;
        }
      }
    }
  }

  .header-bot {
    background: #780600;
    width: 100%;
    height: 65px;
    padding-top: 21px;
    position: relative;

    @media screen and (max-width: 480px) {
      padding-top: 0;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 480px) {
        width: 100%;
        max-width: 100%;
        padding: 0 10px;
      }

      img {
        position: absolute;
        height: 47px;
        margin-top: -28px;
        z-index: 10;

        :hover {
          filter: brightness(110%);
        }

        @media screen and (max-width: 480px) {
          position: relative;
          margin-top: 0;
          width: 150px;
        }
      }

      .exames {
        transition: all 0.3s;
        color: #fff;
        font-weight: 500;
        margin-left: 5px;
        display: none;
        font-size: 10px;
        line-height: 11px;
        text-align: center;
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 5px;
        :hover {
          background: #780600;
          color: white;
          opacity: 1;
        }
        @media (max-width: 600px) {
          display: inline;
        }
      }

      ul {
        list-style: none;
        display: flex;
        padding-left: 0;
        width: 800px;
        justify-content: space-between;

        li {
          font-size: 14px;
          text-transform: uppercase;

          a {
            color: white;
            opacity: 0.8;
            display: flex;

            :hover {
              opacity: 7;
            }
          }
          svg {
            position: relative;
            margin-left: 2px;
            margin-top: 2px;
          }
        }
        .BotaoClienteMobile {
          display: none;
          padding: 5px 15px;
          border-radius: 5px;
          transition: all 0.3s;
          color: #780600;
          font-weight: 500;
          margin: 0px 5px;
          background: #fff;
          position: relative;
          width: 35%;
          font-size: 20px;
          :hover {
            color: white;
            opacity: 1;
          }
          @media (max-width: 600px) {
            display: inherit;
          }
        }
      }
    }

    nav {
      @media screen and (max-width: 480px) {
        display: none;
        right: -100%;
        background: #780600;
        position: absolute;
        top: 65px;
        z-index: 1;
        height: calc(100vh - 65px);
        width: 100%;
        display: inherit;
        right: -100%;
        transition: all cubic-bezier(0.47, 0, 0.75, 0.72) 0.3s;
        padding: 5% 5% 10% 5%;
        border-top: 1px solid #fff;

        ul {
          flex-direction: column;

          li {
            margin-bottom: 22.5px;
            font-size: 20px;
          }
        }

        &.is-active {
          transform: translateX(-100%);
        }
      }
    }
  }
`;
