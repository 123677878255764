import React from 'react';

// styles
import { Container } from './styles';

// icons 
import { FcNext } from 'react-icons/fc';

export default function Duvidas() {
  return (
    <>
      <Container>
        <div className="wrapper">
          <img alt="Pesquisa" src={require('../../assets/Duvida.svg')} />
          <h2>Tem alguma dúvida ou gostaria de saber alguma informação?</h2>
          <a href="/informacoes">
            Clique aqui
            <FcNext size={18} />
          </a>
        </div>
      </Container>
    </>
  );
}