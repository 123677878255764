import styled from 'styled-components';

// Footer
export const Container = styled.footer`
  background: #780600;
`;

// Infos
export const Infos = styled.section`
  padding: 60px 0;
  display: grid;
  grid-template-columns: 70% 30%;
  width: 100%;

  @media screen and (max-width: 480px) { 
    grid-template-columns: 100%;
  }

  .links {
    display: flex;
    justify-content: space-between;
    padding-right: 10%;

    @media screen and (max-width: 480px) { 
      display: inherit;
      padding-right: 0;
    }

    .item {
      @media screen and (max-width: 480px) { 
        margin-bottom: 30px;
      }

      h3 {
        color: white;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      a {
        color: white;
        width: 100%;
        display: flex;
        transition: all 0.2s;
        font-size: 13px;
        margin-bottom: 5px;

        :hover {
          opacity: 0.3;
        }
      }
    }
  }

  .logos {
    border-left: 1px solid white;
    padding-left: 10%;

    @media screen and (max-width: 480px) { 
      border: none;
      padding-left: 0;
    }
    
    .bot {
      display: flex;
      width: 60px;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 480px) { 
        width: fit-content;
      }
      
      img {
        margin-top: 20px;
        transition: all 0.3s;
        height: 25px;
        margin-left: 10px;
        :hover {
          transform: scale(1.2);
          opacity: 1;
        }
      }
    }

    img {
      margin-top: 150px;
      margin-left: 20px;
      height: 50px;
      transition: all 0.3s;
      :hover {
        opacity: 0.7;
      }

      @media screen and (max-width: 480px) { 
        margin: 20px auto 0 auto;
        display: block;
        height: 40px;
      }
    }
  }
`;

// Ade
export const Ade = styled.section`
  padding: 20px;
  background: #fff;

  > div {
    text-align: center;
    
    label {
      color: #780600;
      font-size: 14px;
      font-weight: 500;
    }
    a {
      color: #780600;
      transition: all 0.3s;
      :hover {
        :before {
          content: '';
          width: 127px;
          margin-left: 5px;
          margin-top: 25px;
          position: absolute;
          height: 1px;
          background-color: #780600;
        }
      }
    }
  }
`;
