import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import ReactLoading from 'react-loading';

import api from '../../services/api';

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// icons
import { FcNext } from 'react-icons/fc';

// styles
import { Container, OqFazemos, Sobre, Doenças } from './styles';

export default function Home() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  const banners = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // useEffect
  useEffect(() => {
    async function getDados() {
      await api.get('/pages?slug=home', {}).then((response) => {
        setDados(response.data[0]);

        setLoading(false);
      });
    }
    getDados();
  }, []);
  //

  if (loading === true) {
    return (
      <>
        <Helmet>
          <title>Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type="bars" color="#780600" width={100} />
        </section>
      </>
    );
  } else {
    console.log("Teste")
    return (
      <>
        <Helmet>
          <title>Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="marca, campanha, site, impresso, facebook, identidade visual, logo"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <main>
          <Container>
            <Slider {...banners}>
              {!!dados &&
                dados.acf.banners.map((banner, index) => (
                  <div className="Slide" key={index}>
                    <img alt="Banner1" src={banner.imagem.url} />
                    <div className="wrapper">
                      <div className="Box">
                        <h1>
                          <strong>{banner.titulo}</strong>
                        </h1>
                        <a href={banner.link}>
                          {banner.subtitulo} <FcNext size={18} />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </Container>

          <OqFazemos>
            <div className="Fundo"></div>
            <div className="wrapper">
              <div className="linha">
                <a href="/subespecialidades">
                  <div className="Box">
                    <img
                      alt="Icone1"
                      src={require('../../assets/IconSub.svg')}
                    />
                    <h2>Subespecialidades</h2>
                    <p>
                      {!!dados && dados.acf.o_que_fazemos.subespecialidades}
                    </p>
                    <button href="/subespecialidades">
                      Saiba mais
                      <FcNext size={18} />
                    </button>
                  </div>
                </a>
                <a href="/servicos">
                  <div className="Box">
                    <img
                      alt="Icone1"
                      src={require('../../assets/IconServ.svg')}
                    />
                    <h2>Serviços</h2>
                    <p>{!!dados && dados.acf.o_que_fazemos.servicos}</p>
                    <button href="/servicos">
                      Saiba mais
                      <FcNext size={18} />
                    </button>
                  </div>
                </a>

                <a href="/pesquisas">
                  <div className="Box">
                    <img
                      alt="Icone1"
                      src={require('../../assets/IconPesq.svg')}
                    />
                    <h2>Pesquisas</h2>
                    <p>{!!dados && dados.acf.o_que_fazemos.pesquisas}</p>
                    <button href="/pesquisa">
                      Saiba mais
                      <FcNext size={18} />
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </OqFazemos>

          <Sobre>
            <div className="item1">
              <img
                alt="Cientista"
                src={require('../../assets/Cientista1.jpg')}
              />
            </div>
            <div className="item">
              <img
                className="cientista"
                alt="Cientista"
                src={require('../../assets/Cientista2.png')}
              />
            </div>
            <div className="item">
              <img
                alt="Clinica"
                src={require('../../assets/FotoClinica.jpg')}
              />
            </div>
            <div className="itemMedica">
              <h2>
                O que é<br />
                Patologia Médica ?{' '}
              </h2>
              <p>{!!dados && dados.acf.o_que_e_patologia_medica_}</p>
              <a href="/pacientes">
                Conheça os nossos Atendimentos
                <FcNext size={18} />
              </a>
            </div>
            <div className="itemClinica">
              <h2>A Clínica </h2>
              <p>{!!dados && dados.acf.a_clinica}</p>
              <a href="/quem-somos">
                Conheça a Patologia Médica
                <FcNext size={18} />
              </a>
            </div>
          </Sobre>

          <Doenças>
            <div className="Fundo"></div>
            <div className="divisao">
              <div>
                <div className="Box">
                  <h2>Doenças cadastradas</h2>
                  <label>
                    Informe-se aqui sobre as doenças que estão cadastradas em
                    nossos bancos de dados
                  </label>
                </div>
                <ul>
                  {!!dados &&
                    dados.acf.doencas_cadastradas.map((doenca, index) => (
                      <li key={index}>{doenca.nome}</li>
                    ))}
                </ul>
                <a href="http://anatomiapatologica.com.br" className="Mais" _blank>
                  Veja mais!
                </a>
              </div>
              <img alt="Pesquisa" src={require('../../assets/banner2.png')} />
            </div>
          </Doenças>

          <Duvidas />

          {/* <Blog>
            <div className="Fundo"></div>
            <div className="wrapper">
              <div className="blog-top">
                <h2>Últimas Notícias</h2>
                <a href="/">
                  Ver todas
                  <FcNext size={18} />
                </a>
              </div>
              <div className="blog-bottom">
                <div className="noticia">
                  <a href="/">
                    <img alt="Noticia1" src={require('../../assets/noticia.png')} />
                  </a>
                  <label>28 de fevereiro de 2020</label>
                  <h3>Saiba se cuidar</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sed diam at ante vulputate egesta…
                  </p>
                  <a href="/">
                    Continuar lendo
                    <FcNext size={18} />
                  </a>
                </div>
              
                <div className="noticia">
                  <a href="/">
                    <img alt="Noticia1" src={require('../../assets/noticia.png')} />
                  </a>
                  <label>28 de fevereiro de 2020</label>
                  <h3>Neymar no Corinthians</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sed diam at ante vulputate egesta…
                  </p>
                  <a href="/">
                    Continuar lendo
                    <FcNext size={18} />
                  </a>
                </div>
              
                <div className="noticia">
                  <a href="/">
                    <img alt="Noticia1" src={require('../../assets/noticia.png')} />
                  </a>
                  <label>28 de fevereiro de 2020</label>
                  <h3>Brasil Hexa 2022</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sed diam at ante vulputate egesta…
                  </p>
                  <a href="/">
                    Continuar lendo
                    <FcNext size={18} />
                  </a>
                </div>
              
                <div className="noticia">
                  <a href="/">
                    <img alt="Noticia1" src={require('../../assets/noticia.png')} />
                  </a>
                  <label>28 de fevereiro de 2020</label>
                  <h3>Corona acabou</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sed diam at ante vulputate egesta…
                  </p>
                  <a href="/">
                    Continuar lendo
                    <FcNext size={18} />
                  </a>
                </div>
              </div>
            </div>
          </Blog> */}

          <InfosDeContato />
        </main>

        <Footer />
      </>
    );
  }
}
