import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';

// api
import api from '../../services/api'; 

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// styles
import { Container } from './styles';

export default function Pacientes() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=aos-pacientes')
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>Aos Pacientes | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
      <>
        <Helmet>
          <title>Aos Pacientes | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <Container>
          <div className="wrapper">
            <div className="Med">
              <div>
                <h1>Aos Pacientes</h1>
                <h2>
                  O que é um Consultório / Laboratório de Patologia (Anatomia
                  Patológica)?
                </h2>
                <p>
                  {!!dados && dados.acf.o_que_e_um_consultorio__laboratorio_de_patologia_anatomia_patologica}
                </p>
                
                <h2>Quem são os médicos patologistas?</h2>
                <p>
                  {!!dados && dados.acf.quem_sao_os_medicos_patologistas}
                </p>
                
                <h2>
                  Quem é responsável pela realização de biópsias e exames
                  citopatológicos?
                </h2>
                <p>
                  {!!dados && dados.acf.quem_e_responsavel_pela_realizacao_de_biopsias_e_exames_citopatologicos}
                </p>

                <h2>
                  Os exames anatomopatológicos ou citopatológicos são
                  automatizados?
                </h2>
                <p>
                  {!!dados && dados.acf.os_exames_anatomopatologicos_ou_citopatologicos_sao_automatizados}
                </p>
              </div>
              <div>
                <img alt="Medico" src={require('../../assets/Paciente.jpg')} />
              </div>
            </div>
          </div>
        </Container>
        
        <Duvidas />
        
        <InfosDeContato />
        <Footer />
      </>
    );
  }
}