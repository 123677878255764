import React from 'react';
import { Helmet } from 'react-helmet';

// components 
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// icons
import { FcNext } from 'react-icons/fc';

// stlyes 
import { Container } from './styles';

export default function Noticias() {
  return (
    <>
      <Helmet>
        <title>Notícias | Patologia Médica </title>
        <meta
          name="description"
          content="Excelência diagnóstica em  anatomia patológica."
        />
        <meta
          name="keywords"
          content="medicos, patologia, médica, consulta, doenças, atendimento"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>

      <Header />

      <Container>
        <div className="wrapper">
          <h1>Últimas notícias</h1>
          
          <div className="grid">
            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Saiba se cuidar</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>
          
            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Neymar no Corinthians</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>
          
            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Brasil Hexa 2022</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>
          
            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Corona acabou</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>

            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Corona acabou</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>

            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Corona acabou</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>

            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Corona acabou</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>

            <div className="noticia">
              <a href="/">
                <img alt="Noticia1" src={require('../../assets/noticia.png')} />
              </a>
              <label>28 de fevereiro de 2020</label>
              <h3>Corona acabou</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sed diam at ante vulputate egesta…
              </p>
              <a href="/">
                Continuar lendo
                <FcNext size={18} />
              </a>
            </div>
          </div>
        </div>
      </Container>

      <Duvidas />
  
      <InfosDeContato />

      <Footer />
    </>
  );
}
