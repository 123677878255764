import styled from 'styled-components';

// Banner
export const Banner = styled.section`
  position: relative;

  @media screen and (max-width: 480px) { 
    margin-top: 110px;
  }

  img {
    width: 100%;
    z-index: -1;
    position: relative;
    max-height: 420px;
    object-fit: cover;
  }
`;

// Informacoes
export const Informacoes = styled.section`
  display: grid;
  margin-top: 50px;
  grid-template-columns: 50% 40%;
  grid-gap: 10%;

  @media screen and (max-width: 480px) { 
    display: inherit;
  }
`;

// Textos
export const Textos = styled.div`
  h1 {
    font-weight: 800;
    font-size: 34px;
    color: #2e2e2e;
    position: relative;
    margin-top: 40px;

    @media screen and (max-width: 480px) { 
      margin-top: 0;
    }

    :before {
      content: '';
      width: 70%;
      height: 5px;
      margin: 0;
      background-color: #780600;
      position: absolute;
      z-index: 99;
      top: 50%;
      transform: translate(60%, 20%);
      /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */

      @media screen and (max-width: 480px) { 
        display: none;
      }
    }
  }

  .equipe {
    margin-top: 75px;

    h2 {
      color: #780600;
      margin-bottom: 30px;
      position: relative;
      font-weight: 800;
      font-size: 34px;
      margin-bottom: 30px;

      :before {
        content: '';
        width: 70%;
        height: 5px;
        margin: 0;
        background-color: #2E2E2E;
        position: absolute;
        z-index: 99;
        top: 50%;
        transform: translate(110%, 20%);
        /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */

        @media screen and (max-width: 480px) { 
          display: none;
        }
      }
    }

    .medico {
      padding-left: 5%;
      display: grid;
      grid-template-columns: 22.5% 70%;
      grid-gap: 7.5%;
      margin-bottom: 50px;

      @media screen and (max-width: 480px) { 
        padding-left: 0;
      }

      img {
        max-width: 100%;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .descricao {
        font-size: 14px;

        h3 {
          font-weight: bold;
          padding-top: 15px;
          margin-bottom: 0;
        }

        ul {
          padding-left: 15px;
          line-height: 150%;
          margin: 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }
`;

// Valores
export const Valores = styled.div`
  background: #F5F5F5;
  padding: 12.5%;
  margin-top: -140px;
  border-radius: 10px;
  height: 100%;

  @media screen and (max-width: 480px) { 
    margin-top: 80px;
  }

  > div {
    margin-bottom: 150px;

    @media screen and (max-width: 480px) { 
      margin-bottom: 70px;
    }

    img {
      height: 60px;
    }

    h3 {
      font-weight: 800;
      font-size: 28px;
    }
  }
`;

// Principios
export const Principios = styled.section`
  display: grid;
  grid-template-columns: 45% 50%;
  grid-gap: 5%;
  margin-bottom: 80px;

  @media screen and (max-width: 480px) { 
    display: inherit;
    margin-top: 30px;
  }

  img {
    width: 100%;
    max-height: 700px;
    object-fit: cover;

    @media screen and (max-width: 480px) { 
      max-height: 400px;
      height: 400px;
    }
  }

  > div {
    padding-left: 3%;
    padding-right: 9vw;

    @media screen and (max-width: 480px) { 
      padding: 0 5%;
    }

    h2 {
      color: #2E2E2E;
      margin-bottom: 30px;
      position: relative;
      font-weight: 800;
      font-size: 34px;
      margin-bottom: 50px;
      width: fit-content;

      @media screen and (max-width: 480px) { 
        font-size: 30px;
        margin: 30px 0 50px 0;
      }

      :before {
        content: '';
        width: 180%;
        height: 5px;
        margin: 0;
        background-color: #780600;
        position: absolute;
        z-index: 99;
        /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
        bottom: -20px;
        right: 0;
      }
    }

    ul {
      padding: 0;
      margin: 0;

      @media screen and (max-width: 480px) {
        padding-left: 30px;
      }

      li {
        font-size: 14px;
        list-style: none;
        margin-bottom: 7.5px;
        position: relative;

        :before {
          content: '';
          width: 20px;
          height: 5px;
          margin: 0;
          background-color: #780600;
          position: absolute;
          z-index: 99;
          /* box-shadow: 1px 1px 5px 1px rgba(0,0,0,.1); */
          top: 8.5px;
          left: -35px;
        }
      }
    }
  }
`;