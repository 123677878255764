import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';

// api
import api from '../../services/api'; 

// components
import Header from '../../components/Header';
import InfosDeContato from '../../components/InfosDeContato';
import Footer from '../../components/Footer';
import Duvidas from '../../components/Duvidas';

// styles
import { Banner, Informacoes, Textos, Valores } from './styles';

export default function Pesquisa() {
  // const
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {

    async function getDados(){
      await api
      .get('/pages?slug=pesquisa', {})
      .then(response => {
        setDados( response.data[0] );

        setLoading(false);
      });
    }
    getDados();
  }, []);

  if ( loading === true ) {
    return (
      <>
        <Helmet>
          <title>Pesquisa | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <section className="loading">
          <ReactLoading type='bars' color='#780600' width={100} />
        </section>
      </>
    );

  } else {
    return (
      <>
        <Helmet>
          <title>Pesquisa | Patologia Médica</title>
          <meta
            name="description"
            content="Excelência diagnóstica em  anatomia patológica."
          />
          <meta
            name="keywords"
            content="medicos, patologia, médica, consulta, doenças, atendimento"
          />
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/screenshot.png'}
          />
        </Helmet>

        <Header />

        <main>
          <Banner>
            <img alt="Banner pesquisa - Patologia Médica" src={require('../../assets/pesquisa/bg_topo.jpg')} />
          </Banner>

          <Informacoes className="wrapper">
            <Textos>
              <div className="sobre">
                <h1>Pesquisa</h1>

                <div>
                  <h2>Cursos e Reuniões Inter-Disciplinares</h2>

                  <p>{!!dados && dados.acf.cursos_e_reunioes_inter}</p>
                </div>
              </div>

              <div className="box">
                <h2>Áreas de Atuação e Especialização</h2>
                <p>{!!dados && dados.acf.areas_de_atuacao_e_especializacao.texto}</p>

                <ul>
                  {!!dados && dados.acf.areas_de_atuacao_e_especializacao.areas_de_atuacao.map( (item, index) => (
                    <li key={index}>
                      <label>{item.area}</label>
                    </li>
                  ))}
                </ul>

                <p>Além das áreas acima temos profissionais com especialização específica em várias sub-áreas de atuação em Anatomia Patológica.</p>

                <img alt="Imagem celula - Patologia Médica" src={require('../../assets/pesquisa/celula.jpg')} />
              </div>
            </Textos>

            <Valores>
              <div>
                <img src={require('../../assets/pesquisa/atividades.svg')} alt="" />
                <h3>Atividades Científicas</h3>
                <div>
                  <h4>Participação em eventos científicos e reciclagem profissional:</h4>
                  <p>{!!dados && dados.acf.participacao_em_eventos_cientificos_e_reciclagem_profissional}</p>
                </div>

                <div className="linha"></div>

                <div>
                  <h4>Participação em Programas de Incentivo a Qualidade:</h4>
                  <p>{!!dados && dados.acf.participacao_em_programas_de_incentivo_a_qualidade}</p>
                </div>

                <div className="linha"></div>

                <div>
                  <h4>Participação em Pós Graduação e linhas de pesquisa:</h4>
                  <p>{!!dados && dados.acf.participacao_em_pos_graduacao_e_linhas_de_pesquisa.texto}</p>

                  <ul>
                    {!!dados && dados.acf.participacao_em_pos_graduacao_e_linhas_de_pesquisa.linhas_de_pesquisa.map( (item, index) => (
                      <li key={index}>
                        <label>{item.item}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Valores>
          </Informacoes>

          <Duvidas />
          
          <InfosDeContato />
        </main>
        
        <Footer />
      </>
    );
  }
}
